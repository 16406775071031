import axios from 'axios'

export const statusCheck = async () => {
  const data = await axios
    .get(`${process.env.NEXT_PUBLIC_API_URL}/status`, {
      timeout: 1000,
    })
    .catch((e) => {
      throw e
    })
  return data
}

export const getBannerData = async () => {
  const data = await axios
    .get(`${process.env.NEXT_PUBLIC_API_URL}/status`)
    .catch((e) => {
      throw e
    })
  return data
}
