import { User } from '@auth0/auth0-spa-js'

interface CheckRoleParams {
  user: User | null | undefined
  requiredRole: string
}

export const hasRequiredRole = ({
  user,
  requiredRole,
}: CheckRoleParams): boolean => {
  const audience = `${process.env.NEXT_PUBLIC_AUDIENCE}/roles`
  return Boolean(user && user[audience]?.includes(requiredRole))
}
