import { useEffect, useState, Dispatch, SetStateAction } from 'react'

type Theme = 'dark' | 'light'

function useDarkMode(): [Theme, Dispatch<SetStateAction<Theme>>] {
  const [theme, setTheme] = useState<Theme>(
    typeof window !== 'undefined' && localStorage.getItem('theme') !== null
      ? (localStorage.theme as Theme)
      : 'dark'
  )

  const colorTheme: Theme = theme === 'dark' ? 'light' : 'dark'

  useEffect(() => {
    const root = window.document.documentElement

    root.classList.remove(colorTheme)
    root.classList.add(theme)

    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', theme)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme])

  return [colorTheme, setTheme]
}

export default useDarkMode
