import Link from 'next/link'
import { useAuth0 } from '@auth0/auth0-react'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { hasRequiredRole } from '@/util/hasRequiredRole'

const UserAuth = () => {
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0()

  const userLinks = [
    {
      url: '/profile',
      text: 'Profile',
      id: 'user:profile_link_click',
    },
    {
      text: 'Logout',
      id: 'user:profile_link_click',
      action: () =>
        logout({
          logoutParams: {
            returnTo: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URL,
          },
        }),
    },
  ]

  return (
    <>
      {isAuthenticated ? (
        user && (
          <>
            <Popover className="relative inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500">
              <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-500 dark:text-white focus:outline-none">
                <span>
                  <img
                    className="inline-block h-8 w-8 rounded-full bg-white"
                    src={`${user?.picture}`}
                    alt="user profile image"
                  />
                </span>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute left-1/4 mt-56 flex w-screen max-w-min -translate-x-1/2 px-4  z-[1000]">
                  <div className="w-40 shrink rounded-xl bg-white dark:bg-dark p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5]">
                    {hasRequiredRole({
                      user,
                      requiredRole: 'admin',
                    }) && (
                      <Link
                        href={'/admin/dashboard'}
                        className="block p-2 hover:border-gray-300 hover:text-gray-400 dark:text-white "
                      >
                        Admin Dash
                      </Link>
                    )}
                    {userLinks.map((item) =>
                      item.url ? (
                        <Link
                          id={item.id}
                          key={item.text}
                          href={item.url}
                          className="block p-2 hover:border-gray-300 hover:text-gray-400 dark:text-white "
                        >
                          {item.text}
                        </Link>
                      ) : (
                        <div
                          id={item.id}
                          key={item.text}
                          className="block p-2 hover:border-gray-300 hover:text-gray-400 dark:text-white cursor-pointer"
                          onClick={item.action}
                        >
                          {item.text}
                        </div>
                      )
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </>
        )
      ) : (
        <button
          className="rounded-md bg-indigo-600 px-1 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
          onClick={() => loginWithRedirect()}
        >
          Signup / Login
        </button>
      )}
    </>
  )
}

export default UserAuth
