import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react'
import posthog from 'posthog-js'
import Layout from '@/components/Layout'
import * as Sentry from '@sentry/browser'
import { useRouter } from 'next/router'
import { Auth0Provider } from '@auth0/auth0-react'
import { ReCaptchaProvider } from 'next-recaptcha-v3'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
})

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': DetailedHTMLProps<
        HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const phKey = process.env.NEXT_PUBLIC_POSTHOG_KEY || ''
    if (phKey.length > 0) {
      posthog.init(phKey)
    }
  }, [])

  const router = useRouter()

  const onRedirectCallback = () => {
    router.replace('/')
  }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
          },
        },
      })
  )

  return (
    <QueryClientProvider client={queryClient}>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_BASE_URL || ''}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ''}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri:
            typeof window !== 'undefined'
              ? window.location.origin
              : process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URL,
          scope: 'openid profile email offline_access',
          audience: process.env.NEXT_PUBLIC_AUDIENCE,
        }}
        useRefreshTokensFallback={true}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <ReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA}
          useEnterprise
        >
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ReCaptchaProvider>
      </Auth0Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
