import Navbar from '@/components/Navbar'
import { ReactNode, useEffect } from 'react'
import { initializeSentry } from '@/util/Sentry'
import Banner from '@/components/Banner'

type Props = {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  useEffect(() => {
    initializeSentry()
  }, [])

  return (
    <>
      <Banner />
      <Navbar />
      <main>{children}</main>
    </>
  )
}
